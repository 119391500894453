import { Link } from 'react-router-dom';
import styled /*{ css, keyframes }*/ from 'styled-components';

// const rotate = keyframes`
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//     border-radius: 4px;
//   }
// `;

// const animation = props =>
//   css`
//     ${rotate} ${props.animationLength} 2s linear;
//   `
export default styled(Link)`
  display: inline-flex;
  padding: 0.5em 2em;
  text-decoration: none;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 16px;
  border: unset;
  color: #3f51b5;
  &: active {
  background: #3f51b5;
  color: #fff;
}
& :hover {
  box-shadow: inset 100px 0 0 0 #e0e0e0;
  color: #fff;
}
${
  ({ active }) =>
    active &&
    `background: #3f51b5;
    color: #fff;`
  }`;


