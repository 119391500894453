import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { ANALYTICS } from '../../config';

const useStyles = makeStyles(theme => ({
    homegotop: {
        display: 'flex',
        color: 'black',
        backgroundColor: '#d3d3d385',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function ScrollToTopButton() {
    const classes = useStyles();

    return (
        <IconButton
            className={`${clsx(classes.homegotop, classes.expand, {
                [classes.expandOpen]: true,
            })}`}
            aria-expanded
            aria-label="Mostrar más"
            onClick={() => ANALYTICS.trackScrollToTop()}
        >
            <ExpandMoreIcon />
        </IconButton>
    );
}