import React, { useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '../../components/Button';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ANALYTICS } from '../../config';

const useStyles = makeStyles((theme) => ({
    tipo: {
        textAlign: 'initial'
    },
    card: {
        minWidth: 275,
        marginBottom: '5%',
        marginTop: '5%',
    },
    flex: {
        display: 'flex !important',
    },
    '& .MuiInputBase-inputMultiline': {
        resize: 'vertical',
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            // width: '33ch',
        },
    },
}));
const TextFieldWithValidation = withStyles({
    root: {
        '& input[value=""]': {
            borderColor: 'yellow !important',
            borderWidth: 2,
        },
        // '& input:valid[aria-invalid=false] + fieldset, & textarea:valid + fieldset': {
        //   borderColor: 'green !important',
        //   borderWidth: 2,
        // },
        // '& input:invalid[aria-invalid=true] + fieldset, & textarea:invalid + fieldset': {
        //   borderColor: 'red',
        //   borderWidth: 2,
        // },
        '& input:valid:focus + fieldset, & textarea:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important', // override inline-style
        },
    },
})(TextField);
export default function ContactForm() {
    const [form, setForm] = useState({
        nombre: '',
        apellidos: '',
        email: '',
        tipo: '',
        telefono: '',
        mensaje: '',
    });
    const [emailSuccess, setEmailSuccess] = useState(false);
    const API_PATH = 'https://jsiria.es/testmail.php';
    const formRef = useRef(true)
    const textRegex = RegExp(/[a-zA-Z][a-zA-Z ]+/);
    const mensajeRegex = RegExp(/[a-zA-Z]{2,}/);
    const emailRegex = RegExp(/[a-z0-9]+([-+._][a-z0-9]+){0,5}@.*?(\.(a(?:[cdefgilmnoqrstuwxz]|ero|(?:rp|si)a)|b(?:[abdefghijmnorstvwyz]iz)|c(?:[acdfghiklmnoruvxyz]|at|o(?:m|op))|d[ejkmoz]|e(?:[ceghrstu]|du)|f[ijkmor]|g(?:[abdefghilmnpqrstuwy]|ov)|h[kmnrtu]|i(?:[delmnoqrst]|n(?:fo|t))|j(?:[emop]|obs)|k[eghimnprwyz]|l[abcikrstuvy]|m(?:[acdeghklmnopqrstuvwxyz]|il|obi|useum)|n(?:[acefgilopruz]|ame|et)|o(?:m|rg)|p(?:[aefghklmnrstwy]|ro)|qa|r[eosuw]|s[abcdeghijklmnortuvyz]|t(?:[cdfghjklmnoprtvwz]|(?:rav)?el)|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw])\b){1,2}/);
    const phoneRegex = RegExp(/(^\+[0-9]{9}|00[0-9]{2})?[ -]*[0-9][ -]*([0-9][ -]*){8}$/);
    const selectOptions = [
        {
            value: 'particular',
            label: 'Particular',
        },
        {
            value: 'empresa',
            label: 'Empresa',
        },
    ];

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleFormSubmit = () => {
        ANALYTICS.trackContactFormSubmitClick();
        if (formRef.current.reportValidity() && formRef.current.querySelector('.Mui-error') === null) {
            const url = API_PATH;
            const options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify(form)
            };

            fetch(url, options)
                .then(result => {
                    ANALYTICS.trackContactFormSuccess();
                    setEmailSuccess(true)
                })
                .catch(error => setEmailSuccess(false));
        } else return false;
    };
    const classes = useStyles();
    return (
        <>
            <form ref={formRef} className={classes.root} id="contactForm">
                <Grid container className={classes.flex}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextFieldWithValidation
                            className={classes.flex}
                            name="nombre"
                            error={form.nombre.length > 0 && !textRegex.test(form.nombre)}
                            value={form.nombre}
                            onChange={e => setForm({ ...form, nombre: e.target.value })}
                            label="Nombre"
                            variant="outlined"
                            required
                        />
                        <TextFieldWithValidation
                            className={classes.flex}
                            name="apellidos"
                            error={form.apellidos.length > 0 && !textRegex.test(form.apellidos)}
                            value={form.apellidos}
                            onChange={e => setForm({ ...form, apellidos: e.target.value })}
                            label="Apellidos"
                            variant="outlined"
                            required
                        />
                        <TextFieldWithValidation
                            className={classes.flex}
                            name="email"
                            error={form.email.length > 0 && !emailRegex.test(form.email)}
                            value={form.email}
                            onChange={e => setForm({ ...form, email: e.target.value })}
                            label="Email"
                            type="email"
                            variant="outlined"
                            required
                        />
                        <TextFieldWithValidation
                            className={classes.flex}
                            label="Teléfono"
                            error={form.telefono.length > 0 && !phoneRegex.test(form.telefono)}
                            variant="outlined"
                            value={form.telefono}
                            onChange={e => setForm({ ...form, telefono: e.target.value })}
                            name="telefono"
                            type="tel"
                            pattern="[0-9]{9}"
                            required
                        />
                        <TextFieldWithValidation
                            className={`${clsx(classes.flex, classes.tipo)}`}
                            name="tipo"
                            value={form.tipo}
                            error={form.tipo.length > 0 && !textRegex.test(form.tipo)}
                            onChange={e => setForm({ ...form, tipo: e.target.value })}
                            select
                            label="Tipo"
                            helperText="Cliente particular o empresa"
                            variant="outlined"
                            required
                        >
                            {selectOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextFieldWithValidation>

                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextFieldWithValidation
                            className={classes.flex}
                            label="Mensaje"
                            value={form.mensaje}
                            error={form.mensaje.length > 0 && !mensajeRegex.test(form.mensaje)}
                            onChange={e => setForm({ ...form, mensaje: e.target.value })}
                            name="mensaje"
                            multiline
                            rows={15}
                            variant="outlined"
                            resize="vertical"
                            required
                        />
                    </Grid>
                </Grid>
                <br />
                <div style={{ textAlign: 'center' }}>
                    <Button onClick={handleFormSubmit}>Enviar</Button>
                </div>
            </form>

            <Grid item xs={12} >
                <br />
                {emailSuccess && <Alert>Gracias, le contestaré lo antes posible.</Alert>}
            </Grid>
        </>)
}
