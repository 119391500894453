// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel';
import { isIE, isMobileSafari, isSafari } from 'mobile-device-detect';

import A from '../A';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import LazyLoad from 'react-lazyload';
import LinkIcon from '@material-ui/icons/Link';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleModal from '../SimpleModal';
import Typography from '@material-ui/core/Typography';
import YouTubeIcon from '@material-ui/icons/YouTube';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ANALYTICS } from '../../config';

function CustomCard(props) {
  CustomCard.propTypes = {
    i: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
  };
  const [projectCarousel, setProjectCarousel] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (name) => { 
    ANALYTICS.trackProjectMoreInfo(name);
    setExpanded(prevState => !prevState);
  }
  const { item, i } = props;
  // no webp support? fall back to png/jpeg
  const useImageFallback =
    (isSafari || isMobileSafari || isIE) &&
    item.imageUrlFallback &&
    item.imageUrlFallback.length !== 0;
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: 'transparent',
      color: '#fff',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }));
  const classes = useStyles();

  const createSlide = image => (
    <Slide
      key={item.id}
      media={<img src={image} alt={item.name} />}
      mediaBackgroundStyle={{
        backgroundColor: 'black',
        height: '100%',
        borderRadius: 'none',
      }}
      style={{ backgroundColor: 'black' }}
      title=""
      subtitle=""
    />
  );
  return (
    <LazyLoad height={200} offset={100}>
      <Card className={classes.card}>
        <CardHeader
          // action={
          //   <IconButton aria-label="settings">
          //     <MoreVertIcon />
          //   </IconButton>
          // }
          title={item.name}
        // subheader={item.date ? item.date : ''}
        />
        <A
          onClick={() =>{
            ANALYTICS.trackProjectCardOpen(item.name);
            setProjectCarousel(prevProjectCarousel =>
              Object.assign([...prevProjectCarousel], { [i]: true }),
            )}
          }
        >
          <CardMedia
            className={classes.media}
            image={useImageFallback ? item.cardImageUrlFallback : item.cardImageUrl}
            title={item.name}
          />
        </A>
        <div style={{ position: 'relative' }}>
          <AutoRotatingCarousel
            autoplay={false}
            mobile
            label={<HighlightOffIcon fontSize="large" />}
            contentStyle={{
              borderRadius: '0',
            }}
            open={projectCarousel[i]}
            buttonProps={{
              classes: {
                root: {
                  backgroundColor: 'transparent !important',
                  color: '#fff',
                },
                label: {
                  backgroundColor: 'transparent !important',
                  color: '#fff',
                },
              },
            }}
            onClose={() =>
              setProjectCarousel(prevProjectCarousel =>
                Object.assign([...prevProjectCarousel], { [i]: false }),
              )
            }
            onStart={() =>
              setProjectCarousel(prevProjectCarousel =>
                Object.assign([...prevProjectCarousel], { [i]: false }),
              )
            }
            style={{ position: 'absolute', borderRadius: 'none' }}
          >
            {useImageFallback
              ? item.imageUrlFallback.map(image => createSlide(image))
              : item.imageUrl.map(image => createSlide(image))}
          </AutoRotatingCarousel>
        </div>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {item.summary}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton
            aria-label="YouTube"
            style={{
              display: !item.youtubeLink ? 'none' : 'block',
            }}
          >
            <A
              href={item.youtubeLink}
              target="_blank"
              aria-label="Ver en YouTube"
              title="Ver en YouTube"
            >
              <YouTubeIcon />
            </A>
          </IconButton>

          <IconButton
            aria-label="link"
            style={{
              display: !item.link ? 'none' : 'block',
            }}
          >
            <A
              href={item.link}
              target="_blank"
              aria-label="Abrir link"
              title="Abrir link"
            >
              <LinkIcon />
            </A>
          </IconButton>
          <IconButton aria-label="compartir" title="Compartir" onClick={()=> ANALYTICS.trackProjectSharePopupOpen()}>
            <SimpleModal link={`${window.origin}/${item.year}/${item.id}`} />
          </IconButton>
          <IconButton
            style={
              item.description && item.description.length
                ? null
                : { display: 'none' }
            }
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={() => handleExpandClick(item.name)}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography
              paragraph
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </CardContent>
        </Collapse>
      </Card >
    </LazyLoad >
  );
}

export default CustomCard;
