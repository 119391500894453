import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import HeaderLink from './HeaderLink';
import messages from './messages';
import './typeWriterHeader.css';
import { ANALYTICS } from '../../config';

function Header() {
  const location = useLocation();

  return (
    <div className="container" style={{ textAlign: 'center' }}>
      <div className="css-typing">
        <p style={{ marginBottom: '0px' }}>Jonatan Siria</p>
        <p style={{ marginTop: '0px' }}>Interiorista</p>
      </div>
      <NavBar>
        <HeaderLink
          to="/"
          active={location.pathname !== '/cv' && location.pathname !== '/contacto' ? 1 : 0}
          onClick={()=> ANALYTICS.trackNavigation('home')}
          >
          <FormattedMessage {...messages.home} />
        </HeaderLink>
        <HeaderLink
          to="/cv"
          active={location.pathname === '/cv' ? 1 : 0}
          onClick={()=> ANALYTICS.trackNavigation('cv')}
        >
          <FormattedMessage {...messages.features} />
        </HeaderLink>
        <HeaderLink
          to="/contacto"
          active={location.pathname === '/contacto' ? 1 : 0}
          onClick={()=> ANALYTICS.trackNavigation('contacto')}
        >
          <FormattedMessage {...messages.contact} />
        </HeaderLink>
      </NavBar>
    </div >
  );
}

export default Header;
