// import Wrapper from './Wrapper';
import Button from '../Button';
import CustomCard from '../CustomCard';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useLayout from '../../hooks/useLayout';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardContainer: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


function List(props) {
  const classes = useStyles();
  const ComponentToRender = props.component;
  const { id } = useParams();
  const { isGrid } = useLayout();

  List.propTypes = {
    items: PropTypes.array,
  };
  let content = <div />;
  let projectsAvailable = [];
  projectsAvailable =
    props.items && props.items[0];
  // If we have items, render them
  if (projectsAvailable && projectsAvailable.length) {
    content = props.items[0].map(
      (item, i) =>
        (item.id === id || !id) && (
          <Grid item xs={12} md={isGrid ? 6 : 12} lg={isGrid ? 4 : 12} key={item.id} >
            <div className={classes.cardContainer} style={{ textAlign: 'left' }} id={item.id} >
              <CustomCard item={item} i={i} key={i} />
            </div>
          </Grid >
        ),
    );
    if (id !== undefined && projectsAvailable.length === 1) {
      content = (
        <Grid item xs={12}>
          <div className={classes.cardContainer}>
            {content}
          </div>
          <br />
          <Button href="/">Volver</Button>
        </Grid >
      );
    }
  } else {
    // Otherwise render a single component
    content = (
      <Grid item xs={12}>
        <div>
          <br />
          <div>No se encontraron proyectos con los criterios seleccionados.</div>
          <br />
          <Button href="/" style={{ textAlign: 'center' }}>Volver</Button>
        </div>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {ComponentToRender || content}
      </Grid>
    </div >)
}
export default List;
