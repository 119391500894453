export const PROJECTS_REQUEST_URL = `/projects.json`;
export const RESUME_REQUEST_URL = `/resume.json`;
export const CONTACT_REQUEST_URL = `/contact.json`;
//ToDo build this dinamically
export const DEFAULT_MARKS = [
    {
        value: 2017,
        label: '2017',
    },
    {
        value: 2018,
        label: '2018',
    },
    {
        value: 2019,
        label: '2019',
    },
    {
        value: 2020,
        label: '2020',
    },
    {
        value: 2021,
        label: '2021',
    },
];
export const DEFAULT_MIN_YEAR = 2017;
export const DEFAULT_MAX_YEAR = 2021;
export const ANALYTICS = {
    trackProjectCardOpen: (name) => window._paq.push(['trackEvent', 'Projects', 'Card Click', name]),
    trackProjectMoreInfo: (name) => window._paq.push(['trackEvent', 'Projects', 'More Info Click', name]),
    trackProjectSharePopupOpen: (name) => window._paq.push(['trackEvent', 'Projects', 'Share popup open']),
    trackProjectShare: (socialNetwork, link) => window._paq.push(['trackEvent', 'Projects', `Shared on ${socialNetwork}`, link]),
    trackExperienceMoreInfo: (name) => window._paq.push(['trackEvent', 'Experience', 'More Info Click', name]),
    trackContactFormSubmitClick: () => window._paq.push(['trackEvent', 'ContactForm', 'Submit Form Click']),
    trackContactFormSuccess: (data) => window._paq.push(['trackEvent', 'ContactForm', 'Success!']),
    trackNavigation: (pageTitle) => window._paq.push(['trackPageView', pageTitle]),
    trackLayoutChange: (name) => window._paq.push(['trackEvent', 'Layout', 'Changed to', name]),
    trackScrollToTop: () => window._paq.push(['trackEvent', 'Scroll', 'Scroll to top Click']),
}