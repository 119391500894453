import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    margin: 0 !important;
  }

  body {
    font-family: 'Roboto', sans-serif;
  }

  body.fontLoaded {
    font-family: 'Roboto', sans-serif;
  }

  #root {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: 'Roboto', sans-serif;
    line-height: 1.5em;
  }

  .MuiIconButton-root, .MuiIconButton-root:hover {
    font-size: unset !important;
  }

  .MuiButton-contained {
    color: #fff !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  ::selection {
    background-color: #3f51b5;
    color: #fff;
    text-shadow: none;
}
`;

export default GlobalStyle;
