import './index.css';

import * as serviceWorker from './serviceWorker';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { isMobile, isTablet } from 'mobile-device-detect';

import ContactPage from './containers/ContactPage';
import Footer from './components/Footer';
import GlobalStyle from './global-styles';
import Header from './components/Header';
import { Helmet } from 'react-helmet';
import HomePage from './containers/HomePage';
import { IntlProvider } from 'react-intl';
import { LayoutProvider } from './context/ProjectLayout';
import React from 'react';
import ReactDOM from 'react-dom';
import ResumePage from './containers/ResumePage';
import ScrollToTop from 'react-scroll-up';
import ScrollToTopButton from './components/ScrollToTopButton';
import styled from 'styled-components';
// import { Alert } from '@material-ui/lab';

const AppWrapper = styled.div`
  max-width: ${isMobile && !isTablet ? '90%' : 'min(800px, 60%)'};
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  padding: 0 16px;
  flex-direction: column;
`;

ReactDOM.render(
  <React.StrictMode>
    {/* ToDo enable if we ever use cookies <Alert severity="warning">Esta página web usa cookies <b>exclusivamente</b> para entender cómo usas la página y en ningún caso transmite tu información a terceros. Si continúas navegando consideramos que aceptas el uso de cookies. <a href="https://jsiria.es/privacidadcookies.htm">Más información</a>.</Alert> */}
    <AppWrapper id="app-global-wrapper">
    
      <Helmet
        titleTemplate="%s - Jonatan Siria Sánchez"
        defaultTitle="Jonatan Siria Sánchez"
      >
        <meta
          name="description"
          content="Portfolio de Jonatan Siria Sánchez, interiorista."
        />
        <meta
          property="og:title"
          content="Jonatan Siria Sánchez, interiorista"
        />
        <meta property="og:site_name" content="Jonatan Siria Sánchez" />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:url" content="https://jsiria.es/" />
        <script type="text/javascript">var _paq=window._paq=window._paq||[];_paq.push(['trackPageView']);_paq.push(['enableLinkTracking']);(function()&#123;var u='https://rpi58.duckdns.org:3300/';_paq.push(['setTrackerUrl',u+'matomo.php']);_paq.push(['setSiteId','1']);var d=document,g=d.createElement('script'),s=d.getElementsByTagName('script')[0];g.type='text/javascript';g.async=true;g.src=u+'matomo.js';s.parentNode.insertBefore(g,s);&#125;)();</script>
      </Helmet>
      <IntlProvider locale="es">
      <LayoutProvider>
        <Router>
          <Header />
          <Switch>
              <Route exact path="/" component={HomePage} />
            <Route exact path="/cv" component={ResumePage} />
            <Route exact path="/contacto" component={ContactPage} />
            <Route path="/:year?/:id?" component={HomePage} />
          </Switch>
        </Router>
      </LayoutProvider>
      <Footer />
      </IntlProvider>
    </AppWrapper>
    <GlobalStyle />
    <ScrollToTop showUnder={160} duration={500}>
      <ScrollToTopButton />
    </ScrollToTop>
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
