import styled from 'styled-components';

const Wrapper = styled.footer`
  justify-content: space-between;
  padding: 3em 0;
  border-top: 1px solid #666;
  flex: 1 0 100%;
  text-align: center !important;
`;

export default Wrapper;
