import React from "react";
import { Helmet } from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ContactForm from "../../components/ContactForm";

export default function AboutPage() {
  return (
    <div>
      <Helmet>
        <title>Contacto</title>
        <meta name="contacto" content="contáctame, acerca de, sobre mí" />
      </Helmet>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Card
            style={{
              minWidth: 275,
              marginBottom: "5%",
              marginTop: "5%",
            }}
          >
            <CardContent>
              <ContactForm />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
