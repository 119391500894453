import { DEFAULT_MARKS, DEFAULT_MAX_YEAR, DEFAULT_MIN_YEAR, PROJECTS_REQUEST_URL } from '../../config/index';
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import CustomSwitch from '../../components/CustomSwitch';
import { Helmet } from 'react-helmet';
import ProjectList from '../../components/ProjectList';
import Section from './Section';
import Slider from '@material-ui/core/Slider';
import { useFetch } from '../../hooks/useFetch';
import useLayout from '../../hooks/useLayout';

/*
 * HomePage
 *
 * Project list and grid
 */
const HomePage = () => {

  const { isLoading, data: allProjects, error } = useFetch(PROJECTS_REQUEST_URL);
  const { isGrid } = useLayout();
  const { year, id } = useParams();

  const [selectedYear, setSelectedYear] = useState(year);
  const [projects, setProjects] = useState([]);
  const [projectCount, setProjectCount] = useState('');
  const history = useHistory();
  const ProjectListProps = {
    loading: isLoading,
    error: error,
    projects: projects,
  };

  useEffect(() => {
    if (allProjects.length) {
      if (id !== undefined || selectedYear !== undefined) {
        let auxFilteredProjects = [];
        auxFilteredProjects = id !== undefined ? [Array.from(allProjects).filter(e => e.id === id)] : [Array.from(allProjects).filter(e => e.year === selectedYear)];
        setProjectCount(auxFilteredProjects[0]?.length);
        setProjects(auxFilteredProjects);
      } else {
        setProjects(prevState => [allProjects])
      }
    }
  }, [allProjects, id, selectedYear]);

  return (
    <article>
      <Helmet>
        <title>Proyectos</title>
        <meta name="description" content="Portfolio" />
      </Helmet>
      <div>
        <Section style={{ marginTop: '2em' }}>
          {selectedYear !== undefined && !isGrid && <Slider
            name="slider"
            value={Number(selectedYear)}
            valueLabelFormat={projectCount?.toString()}
            valueLabelDisplay={projectCount === '' ? 'off' : 'on'}
            marks={DEFAULT_MARKS}
            min={DEFAULT_MIN_YEAR}
            max={DEFAULT_MAX_YEAR}
            onChange={(event, value) => {
              history.push(`/${value}`);
              setSelectedYear(value.toString());
            }}
          />
          }
          <>
            <CustomSwitch hidden={id !== undefined} />
            <ProjectList {...ProjectListProps} />
          </>
        </Section>

      </div>
    </article >
  );
}

export default React.memo(HomePage);

// HomePage.propTypes = {
//   loading: PropTypes.bool,
//   error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
//   projects: PropTypes.oneOfType([
//     PropTypes.array,
//     PropTypes.bool,
//     PropTypes.any,
//   ]),
// };