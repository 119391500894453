import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { ANALYTICS } from '../../config';

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 275,
    marginBottom: '5%',
    marginTop: '5%',
  },
  pos: {
    marginBottom: 12,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function SimpleCard(props) {
  const classes = useStyles();
  const { title, subtitle, description, date, projects } = props.experience;
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = (name) => { 
    ANALYTICS.trackExperienceMoreInfo(name);
    setExpanded(prevState => !prevState);
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {`${subtitle} | ${date}`}
        </Typography>
        <Typography variant="body1" component="p" dangerouslySetInnerHTML={{ __html: description }}>
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton
          style={projects && projects.length ? null : { display: 'none' }}
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={() => handleExpandClick(title)}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            paragraph
            dangerouslySetInnerHTML={{ __html: projects }}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}
