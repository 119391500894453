import React, { useState } from "react";
import { ANALYTICS } from './../config';
import { isMobile } from 'mobile-device-detect';

const defaultLayout = {
  isGrid: !isMobile
};

export const LayoutProvider = ({ children, isGrid }) => {
  const [currentLayout, setCurrentLayout] = useState(
   isGrid || defaultLayout
  );

  const changeLayout = (values) => {
    ANALYTICS.trackLayoutChange(values ? 'grid' : 'list');
    setCurrentLayout(values)
  };

  return (
    <LayoutContext.Provider value={{ isGrid: currentLayout, changeLayout }}>
      {children}
    </LayoutContext.Provider>
  );
};

const LayoutContext = React.createContext({ layout: defaultLayout, changeLayout: LayoutProvider.changeLayout });

export const LayoutConsumer = LayoutContext.Consumer;

export default LayoutContext;