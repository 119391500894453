import { useEffect, useState } from "react";
const cache = {};

export const useFetch = (url) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // alternatively
        // https://cors-anywhere.herokuapp.com/https://jsiria.es${url}`;
        url = `https://jsonp.afeld.me/?url=https://jsiria.es/${url}`;
    }

    useEffect(() => {
        //ToDo cache this
        // console.log(`useFetch url ${url}`)
        if (!url) return;
        const checkStatus = (res) => {
            const contentType = res.headers.get("content-type");
            if ((contentType && contentType.indexOf("application/json") === -1) || (res.status < 200 && res.status > 300)) {
                setIsLoading(false);
                setError(true);
                return false;
            }
            return true;
        }
        const fetchData = async () => {
            setIsLoading(true);
            if (cache[url]) {
                const data = cache[url];
                setData(data);
                setIsLoading(false);
            } else {
                const response = await fetch(url);
                await checkStatus(response);
                if (checkStatus) {
                    const data = await response.json();
                    cache[url] = data; // set response in cache;
                    setIsLoading(false);
                    setData(data);
                }
            }
        };

        fetchData();
    }, [url]);
    return { isLoading, data, error };
};
