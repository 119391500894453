import { Helmet } from 'react-helmet';
import { RESUME_REQUEST_URL } from '../../config/index';
import React from 'react';
import Simplecard from '../../components/SimpleCard';
import { useFetch } from '../../hooks/useFetch';

/*
 * ResumePage
 *
 * List work experience
 */
export default function ResumePage() {
  const { data: resume } = useFetch(RESUME_REQUEST_URL);

  return (
    <div>
      <Helmet>
        <title>Experiencia</title>
        <meta name="description" content="Currículum" />
      </Helmet>
      <>
        {resume.map((resumeItem, i) => (
          <Simplecard experience={resumeItem} key={i} />
        ))}
      </>
    </div>
  );
}
