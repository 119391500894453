import React, { useEffect } from 'react';
import { isMobile, isTablet } from 'mobile-device-detect';

import GridOnIcon from '@material-ui/icons/GridOn';
import ListIcon from '@material-ui/icons/List';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import useLayout from '../../hooks/useLayout';

const useStyles = makeStyles((theme) => ({
    toggleContainer: {
        margin: 'auto',
        marginBottom: '2%',
        textAlign: 'right',
    },
    toggleButton: {
        '&.Mui-selected': {
            color: 'white',
            backgroundColor: theme.palette.primary.main
        },
    },
}));

export default function CustomSwitch(props) {
    const { isGrid, changeLayout } = useLayout();
    const [layoutValue, setLayoutValue] = React.useState(isGrid ? 'grid' : 'list');
    useEffect(() => {
        let wrapper = document.getElementById('app-global-wrapper');
        if (isGrid && window.location.pathname !== "/cv") {
            wrapper.style.maxWidth = "90%";
        } else {
            wrapper.style.maxWidth = "60%";
        }
        if (isMobile && !isTablet) wrapper.style.maxWidth = "100%";
        return () => {
            if (!isMobile && (window.location.pathname === "/cv" || window.location.pathname === "/contacto" || !isGrid)) {
                wrapper.style.maxWidth = "60%";
            }
        };
    }, [isGrid]);

    const handleLayout = (event, newLayout) => {
        if (newLayout !== null) {
            setLayoutValue(newLayout)
            changeLayout(newLayout === 'grid');
        }
    };
    const classes = useStyles();

    return (
        <div className={classes.toggleContainer} hidden={props.hidden || (isMobile && !isTablet)} >
            <ToggleButtonGroup
                value={layoutValue}
                exclusive
                onChange={handleLayout}
                aria-label="change layout"
            >
                <ToggleButton disabled={layoutValue === 'list'} className={classes.toggleButton} value="list" aria-label="list">
                    <ListIcon />
                </ToggleButton>
                <ToggleButton disabled={layoutValue === 'grid'} className={classes.toggleButton} value="grid" aria-label="grid">
                    <GridOnIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        </div >
    );
}
